jQuery(document).ready(function () {
  jQuery(".accordion-content").on("show.bs.collapse", function () {
    // var id = jQuery(this).attr("data-service-id");

    // Hide all asides
    //jQuery(".card-aside").removeClass('inplace');

    // Show this aside
    //jQuery("#card-aside-" + id).addClass('inplace');

    var element_id = jQuery(this).attr("id");
    console.log(element_id);
    // This accordion
    var this_accordion_id = jQuery(this).attr("data-parent"); // has hash

    // Fidn the parent card and add active to it.
    jQuery(this_accordion_id + " .card").removeClass("active");
    jQuery(this_accordion_id + " .card").removeClass("inplace");
    jQuery("#" + element_id)
      .parent()
      .parent()
      .addClass("active");

    // Add inplace after .2 seconds
    setTimeout(function () {
      jQuery("#" + element_id)
        .parent()
        .parent()
        .addClass("inplace");
    }, 220);
  });
});

jQuery(document).ready(function () {
  var age_gate = readCookie("age_gate");
  if (age_gate !== "yes") {
    jQuery(".age-gate-container").addClass("inplace");
    jQuery("body").addClass("age-gate");
  } else {
    console.log("Age gate passed");
  }

  jQuery("#age-gate-yes").click(function (event) {
    createCookie("age_gate", "yes", 30);
    jQuery(".age-gate-container").removeClass("inplace");
    jQuery("body").removeClass("age-gate");

    return false;
  });
});

jQuery(document).ready(function () {
  var store_state = readCookie("store_state");
  var martin_checkout_method = readCookie("martin_checkout_method");

  console.log("checkout cookie: " + martin_checkout_method);

  // If they have a state stored in their cookies, update the UI.
  if (store_state != "" && store_state != null) {
    jQuery("#state-indicator").html("Shipping State: " + store_state);
    // Update select list as well.
    if (jQuery("#statecheck-state").length > 0) {
      jQuery("#statecheck-state").val(store_state);
    }
  }
  // Make the Shipping State UI element clickable so we can change it.
  jQuery("#state-indicator").on("click", function () {
    // Show the map popup.
    jQuery(".state-map-container").addClass("inplace");
    jQuery("body").addClass("age-gate");
    // Remove error message
    jQuery("#state-error").css("display", "none");
    return false;
  });

  if (jQuery(".state-map").length > 0) {
    // Have them choose a state if we're on the store and they haven't chosen one yet.
    if (
      (store_state == "" || store_state === null) &&
      jQuery(".state-map-container").hasClass("store-page")
    ) {
      jQuery(".state-map-container").addClass("inplace");
      jQuery("body").addClass("age-gate");
    }

    // Click event for states
    jQuery(".state-map svg .st0").click(function (event) {
      var store_state = jQuery(this).attr("id");

      martin_save_state(store_state);

      // Update the dropdown as well.
      jQuery("#statecheck-state").val(store_state);

      return false;
    });

    // Click event for state dropdown
    jQuery("#statecheck-state").change(function () {
      var store_state = jQuery(this).val();

      martin_save_state(store_state);

      return false;
    });
  }
});

function martin_get_store(state) {
  if (state == "") return false;

  for (var i = 0; i < martin_vars.state_stores.length; i++) {
    if (martin_vars.state_stores[i].state == state) {
      return martin_vars.state_stores[i].store;
    }
  }

  return "";
}

// Save the state selection, update UI
function martin_save_state(store_state) {
  // Error check
  var martin_checkout_method = martin_get_store(store_state);

  console.log("Store: " + martin_checkout_method);

  jQuery("#state-indicator").html("Shipping State: " + store_state);
  createCookie("store_state", store_state, 30);

  if (martin_checkout_method != "") {
    createCookie("martin_checkout_method", martin_checkout_method, 30);
    jQuery(".state-map-container").removeClass("inplace");
    jQuery("body").removeClass("age-gate");

    window.location.href = (function (url) {
      url.searchParams.set("state_switch", "1");
      return url.toString();
    })(new URL(window.location.href));
  } else {
    // Mark checkout method as blank.
    createCookie("martin_checkout_method", "", 30);
    jQuery("#state-error").css("display", "block");
    // Browse anyway option.
    jQuery(".browse-anyway").on("click", function () {
      jQuery(".state-map-container").removeClass("inplace");
      jQuery("body").removeClass("age-gate");

      window.location.href = (function (url) {
        url.searchParams.set("state_switch", "1");
        return url.toString();
      })(new URL(window.location.href));

      return false;
    });
  }
}
